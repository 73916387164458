import { Box, Button, Grid, Divider, MenuItem, Table, TableCell, TableHead, TableRow, TableBody, TextField, Typography, TableContainer } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PrintIcon from '@mui/icons-material/Print'
import moment from 'moment'
import * as api from 'src/utils/api/workerAttendanceRecord/AutoPayApi'

export default function PartialBatchGenerate(props) {
  const {
    setOpen,
    tableList,
    createData,
    workerData,
    peopleCount,
    createOk,
    sendData,
    saveData,
    bankData,
    handleSubmitChannel,
    handleSubmitDate,
    handlePrintOut,
    handleMpfDownload,
    handleLetterPayrollPrint,
    handleLetterPayrollDownload,
  } = props
  const { t } = useTranslation()
  const [countWorketIncome, setCountWorketIncome] = useState(0)
  const [aPayroll, setAPayroll] = useState(0)
  const [erMPF, setErMPF] = useState(0)
  const [eeMPF, setEeMPF] = useState(0)

  useEffect(() => {}, [])

  const handlePrint = () => {
    const print = async () => {
      console.log('saveData', saveData)
      console.log('sendData', sendData)
      setOpen(true)

      let data = {
        dateFrom: saveData.bigPay,
        pdDateFrom: saveData.pdDateFrom,
        pdDateTo: saveData.pdDateTo,
        submitDate: sendData.submitDate,
        subBatchId: saveData.shortPay,
        submitchannel: sendData.submitChannel,
      }
      let send = await api.PartialPrintPDF(data)
      setOpen(false)
    }
    print()
  }

  return (
    <>
      <div style={{ display: 'block', textAlign: 'right', marginTop: '0px' }}>
        <Button onClick={handlePrint}>
          <PrintIcon />
          {t('button_print')}
        </Button>
      </div>
      <Typography variant="h4" sx={{ display: 'block', textAlign: 'center' }}>
        <b>{t('create_auto-transfer_files')}</b>
      </Typography>
      {createOk ? (
        <>
          <Button sx={{ my: '20px', mx: '10px' }} variant="contained" onClick={handlePrintOut}>
            {t('Print_The_Worker_MPF_Autopay_Authorization_Form')}
          </Button>
          <Button sx={{ my: '20px', mx: '10px' }} variant="contained" onClick={handleMpfDownload}>
            {t('Download_Workers_MPF_Autopay_File')}
          </Button>
          <Button sx={{ mx: '10px' }} variant="contained" onClick={handleLetterPayrollPrint}>
            {t('Print_the_worker_income_autopay_authorization_form')}
          </Button>
          <Button sx={{ mx: '10px' }} variant="contained" onClick={handleLetterPayrollDownload}>
            {t('Download_worker_income_autopay_file')}
          </Button>
        </>
      ) : null}
      <Typography variant="h6" sx={{ display: 'block', mt: '10px' }}>
        <b>{t('icon1_autopay_button8_print_title')}</b>
      </Typography>
      <Grid container columnSpacing={1}>
        <Grid item xs={6} md={2.5}>
          <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('icon1_autopay_button8_print_label1')}</Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography sx={{ display: 'block', fontSize: '16px' }}>{bankData.vendorData ? bankData.vendorData[0].vendorDesc : ''}</Typography>
        </Grid>
        <Box width="100%" />
        <Grid item xs={6} md={2.5}>
          <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('icon1_autopay_button8_print_label2')}</Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography sx={{ display: 'block', fontSize: '16px' }}>{bankData.mainDate[0]?.bankAccountName || ''}</Typography>
        </Grid>
        <Box width="100%" />
        <Grid item xs={6} md={2.5}>
          <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('icon1_autopay_button8_print_label3')}</Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography sx={{ display: 'block', fontSize: '16px' }}>
            {bankData.mainDate[0]?.bankAccount || ''}
            {createOk && createData.finalVo?.bankaccountnotmatch === 1 ? <font color="red"> (最新版本: {createData.finalVo?.vendorDetail?.bankAccount} ) </font> : null}
          </Typography>
        </Grid>
        <Box width="100%" />
        <Grid item xs={6} md={2.5}>
          <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('icon1_autopay_button8_print_label4')}</Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography sx={{ display: 'block', fontSize: '16px' }}>
            {bankData.mainDate[0]?.schemeNo || ''}
            {createOk && createData.finalVo?.mpfaccountnotmatch === 1 ? <font color="red"> (最新版本: {createData.finalVo?.vendorDetail?.schemeNo})</font> : null} <br />
            {(createOk && createData.finalVo?.bankaccountnotmatch === 1) || (createOk && createData.finalVo?.mpfaccountnotmatch === 1) ? (
              <font color="red">請注意：此銀行戶口號碼或東亞(強積金)行業計劃編號不是最新的版本，系統將會採用最新的版本製作自動轉賬</font>
            ) : null}
          </Typography>
        </Grid>
        <Box width="100%" />
        <Grid item xs={6} md={2.5}>
          <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('icon1_autopay_button8_print_label5')}</Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography sx={{ display: 'block', fontSize: '16px' }}>{bankData.mainDate[0]?.branchCode || ''}</Typography>
        </Grid>
        <Box width="100%" />
        <Grid item xs={6} md={2.5}>
          <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('icon1_autopay_button8_print_label6')}</Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography sx={{ display: 'block', fontSize: '16px' }}>{bankData.mainDate[0]?.contactPerson || ''}</Typography>
        </Grid>
        <Box width="100%" />
        <Grid item xs={6} md={2.5}>
          <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('icon1_autopay_button8_print_label7')}</Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography sx={{ display: 'block', fontSize: '16px' }}>{bankData.mainDate[0]?.phoneNumber || ''}</Typography>
        </Grid>
        <Box width="100%" />
        <Grid item xs={6} md={2.5}>
          <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('Big_Pay_Period')}</Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography sx={{ display: 'block', fontSize: '16px' }}>{bankData.mainDate[0]?.dateFrom + '~' + bankData.mainDate[0]?.dateTo || ''}</Typography>
        </Grid>
        <Box width="100%" />
        <Grid item xs={6} md={2.5}>
          <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('Small_pay_period')}</Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography sx={{ display: 'block', fontSize: '16px' }}>{saveData.shortPay + ' - ' + bankData.mainDate[0]?.dateFrom + '~' + bankData.mainDate[0]?.dateTo || ''}</Typography>
        </Grid>
        <Box width="100%" />
        <Grid item xs={6} md={2.5}>
          {bankData.submit_date === '' ? (
            <Typography sx={{ display: 'block', fontSize: '16px', mt: '10px' }}>{t('icon1_autopay_button8_print_label8')}</Typography>
          ) : (
            <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('icon1_autopay_button8_print_label8')}</Typography>
          )}
        </Grid>
        <Grid item xs={6} md={6}>
          {bankData.dateController === '1' ? (
            <TextField
              disabled={createOk}
              inputProps={{
                max: '2099-12-31',
              }}
              onChange={handleSubmitDate}
              value={moment(sendData.submitDate).format('yyyy-MM-DD')}
              size="small"
              fullWidth
              sx={{ maxWidth: '200px' }}
              type="date"
            />
          ) : (
            <Typography sx={{ display: 'block', fontSize: '16px' }}>{bankData.submit_date || ''}</Typography>
          )}
        </Grid>
        <Box width="100%" />
        <Grid item xs={6} md={2.5}>
          {bankData.submitchannelType === 'String' ? (
            <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('icon1_autopay_button8_print_label9')}</Typography>
          ) : (
            <Typography sx={{ display: 'block', fontSize: '16px', mt: '10px' }}>{t('icon1_autopay_button8_print_label9')}</Typography>
          )}
        </Grid>
        {/* <Grid item xs={6} md={6}>
          {bankData.maxAutoDateSet !== saveData.shortPay ? (
            <Typography sx={{ display: "block", fontSize: "16px" }}>
              {bankData.submitchannelset}
            </Typography>
          ) : (
            <TextField
              disabled={createOk}
              size="small"
              value={sendData.submitChannel || "B"}
              onChange={handleSubmitChannel}
              fullWidth
              sx={{ maxWidth: "200px", mt: "5px" }}
              select
            >
              <MenuItem value="Branch">Branch</MenuItem>
              <MenuItem value="Internet">Internet</MenuItem>
            </TextField>
          )}
        </Grid> */}

        <Grid item xs={6} md={6}>
          {bankData.dateController === '0' ? (
            <Typography sx={{ display: 'block', fontSize: '16px' }}>{bankData.submitchannelset}</Typography>
          ) : (
            <TextField disabled={createOk} size="small" value={sendData.submitChannel || 'Branch'} onChange={handleSubmitChannel} fullWidth sx={{ maxWidth: '200px', mt: '5px' }} select>
              <MenuItem value="Branch">Branch</MenuItem>
              <MenuItem value="Internet">Internet</MenuItem>
            </TextField>
          )}
        </Grid>
        <Box width="100%" />
        <Grid item xs={6} md={2.5}>
          {bankData.submitchannelType === 'String' ? (
            <Typography sx={{ display: 'block', fontSize: '16px' }}>{t('icon1_autopay_button8_print_label10')}</Typography>
          ) : (
            <Typography sx={{ display: 'block', fontSize: '16px', mt: '10px' }}>{t('icon1_autopay_button8_print_label10')}</Typography>
          )}
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography sx={{ display: 'block', fontSize: '16px' }}>Branch</Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mt: '10px' }} />

      {/* table */}
      <Typography variant="h6" sx={{ display: 'block', mt: '20px' }}>
        <b>{t('Workers_Income_And_Bank_Information')}</b>
      </Typography>
      <TableContainer sx={{ maxHeight: '80vh', maxWidth: '100vw' }}>
        <Table
          sx={{
            wordBreak: 'keep-all',
            pageBreakInside: 'inherit',
            mt: '10px',
          }}
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow hover>
              <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>No.</TableCell>
              <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{t('EnglishName')}</TableCell>
              <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{t('ChineseName')}</TableCell>
              <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{t('ID_number')}</TableCell>
              <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Number_Of_Workers_Working_Days')}</TableCell>
              <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Actual_number_of_working_days')}</TableCell>
              <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Worker_income')}</TableCell>
              <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Actual_Payroll')}</TableCell>
              <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Employer_MPF_contributions')}</TableCell>
              <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Employee_MPF_contributions')}</TableCell>
              <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{t('Employee_Account_Number')}</TableCell>
              <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{t('plan_Member_Number')}</TableCell>
              <TableCell sx={{ fontSize: '15px', textAlign: 'left' }}>{t('Remark')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableList.length > 0 ? (
              <>
                {tableList.map((row, index) => (
                  <TableRow
                    hover
                    key={index}
                    sx={{
                      backgroundColor: index % 2 !== 0 ? '#DCDCDC' : '#ffffff',
                    }}
                  >
                    <TableCell
                      sx={{
                        fontSize: '15px',
                      }}
                      component="th"
                      scope="row"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap', fontSize: '15px' }}>{row.englishName} </TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>
                      {row.chineseName} {row.age < 18 || row.ageMon >= 65 ? <b style={{ color: 'red' }}>*</b> : ''}
                    </TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>{row.hkid}</TableCell>
                    <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                      {row.workDay}
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                      {row.workDayAct}
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                      {row.relevantIncomeForMat}
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                      {row.netIncomeForMat}
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                      {row.employerMpfForMat}
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                      {row.employeeMpfForMat}
                    </TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>{row.bankAccount}</TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>{row.mpfScheme}</TableCell>
                    <TableCell align="left" sx={{ fontSize: '15px' }}>
                      {row.payrollRemark}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan="13" sx={{ textAlign: 'center' }}>
                  No Records Found!
                </TableCell>
              </TableRow>
            )}
            <TableRow hover>
              <TableCell sx={{ fontSize: '15px' }} component="th" scope="row" />
              <TableCell sx={{ fontSize: '15px' }} />
              {/* 承判商總額 */}
              <TableCell colSpan="4" sx={{ textAlign: 'right', fontSize: '15px' }}>
                {t('Total_Contractors')}:
              </TableCell>
              <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                {workerData.final_tot_rel_format || 0}
              </TableCell>
              {workerData.periodset?.loanFlag === 'Y' ? (
                <>
                  <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                    {workerData.final_tot_adv_pay_format || 0}
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                    {workerData.final_tot_rtn_pay_format || 0}
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                    {workerData.final_tot_new_pay_format || 0}
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                    {workerData.final_tot_net_format || 0}
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                    {workerData.final_tot_er_mpf_format || 0}
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                    {workerData.final_tot_ee_mpf_format || 0}
                  </TableCell>
                </>
              )}

              <TableCell align="right" sx={{ fontSize: '15px' }} />
              <TableCell align="right" sx={{ fontSize: '15px' }} />
              <TableCell align="right" sx={{ fontSize: '15px' }} />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ marginTop: '50px' }}>
        <TableContainer>
          <Table
            sx={{
              wordBreak: 'keep-all',
              pageBreakInside: 'inherit',
              minWidth: 650,
            }}
            size="small"
          >
            <TableHead>
              <TableRow hover>
                <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('icon1_autopay_button8_print_PeopleCount')}</TableCell>
                <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Worker_income')}</TableCell>
                {workerData.periodset?.loanFlag === 'Y' ? (
                  <>
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Borrowing')}</TableCell>
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Deduct_loan')}</TableCell>
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Income_after_borrowings')}</TableCell>
                  </>
                ) : null}
                <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Actual_Payroll')}</TableCell>
                <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Employer_MPF_contributions')}</TableCell>
                <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Employee_MPF_contributions')}</TableCell>
                <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Total_contributions_from_both_parties')}</TableCell>
                <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{t('Deductions_from_Contractor_Account')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover>
                <TableCell sx={{ fontSize: '15px', textAlign: 'right' }} component="th" scope="row">
                  {peopleCount}
                </TableCell>
                <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{workerData.final_tot_rel_format || '0.00'} </TableCell>
                {workerData.periodset?.loanFlag === 'Y' ? (
                  <>
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{workerData.final_tot_adv_pay_format || '0.00'}</TableCell>
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{workerData.final_tot_rtn_pay_format || '0.00'}</TableCell>
                    <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{workerData.final_tot_new_pay_format || '0.00'}</TableCell>
                  </>
                ) : null}
                <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}>{workerData.final_tot_net_format || '0.00'}</TableCell>
                <TableCell sx={{ fontSize: '15px', textAlign: 'right' }}> {workerData.final_tot_er_mpf_format || '0.00'}</TableCell>
                <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                  {workerData.final_tot_ee_mpf_format || '0.00'}
                </TableCell>
                <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                  {(workerData.final_tot_ee_mpf + workerData.final_tot_er_mpf).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}
                </TableCell>
                <TableCell align="right" sx={{ fontSize: '15px', textAlign: 'right' }}>
                  {(workerData.final_tot_ee_mpf + workerData.final_tot_er_mpf + workerData.final_tot_net).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || '0.00'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  )
}

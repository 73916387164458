import { React, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import ReactToPrint from 'react-to-print'
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  Container,
  TextField,
  MenuItem,
  Grid,
  FormControl,
  Button,
  TableContainer,
  TableHead,
  RadioGroup,
  TableBody,
  FormControlLabel,
  TableRow,
  Radio,
  TableCell,
  Table,
  Paper,
  tableCellClasses,
  Typography,
  Divider,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import $http, { $download } from 'src/utils/api/services'
import moment from 'moment'
import { parseInt } from 'lodash'
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'
import InputDropDown from '../../../components/use-form/InputDropDown'
import InputText from '../../../components/use-form/InputText'
import InputDatePicker from '../../../components/use-form/InputDatePicker'

import * as api from '../../../utils/api/workerAttendanceRecord/AccRecSearchApi'

export default function PersonalEnterSearchWithVendor() {
  const [isSearchPage, setIsSearchPage] = useState(true)
  const { t } = useTranslation()
  const printComponentRef = useRef()
  const [backOpen, setBackOpen] = useState(false)
  const [siteList, setSiteList] = useState([])
  const [subList, setSubList] = useState([])
  const [smartCard, setSmartCard] = useState([])
  const [sendData, setSendData] = useState({})
  const [tableData, setTableData] = useState([])
  const [vendorDesc, setVendorDesc] = useState({})
  const [vendorDescN, setVendorDescN] = useState('')
  const [vendorId, setVendorId] = useState('')
  const [vendor, setVendor] = useState({})
  const [vendorList, setVendorList] = useState([])
  const [site, setSite] = useState({})

  const handleBack = () => {
    setIsSearchPage(true)
  }

  useEffect(() => {
    setBackOpen(true)
    const getData = async () => {
      let getFillSiteWithVendorAll = await apiAtt.getFillSiteWithVendorAll()

      if (getFillSiteWithVendorAll) {
        setVendorList(getFillSiteWithVendorAll)
      }

      let getSmartCard = await api.getSmartCard()

      if (getSmartCard) {
        setSmartCard(getSmartCard)
      }
      setSendData({ ...sendData, toc: null, face: null, dateFrom: moment(getPreMonth(moment(new Date()).format('yyyy-MM-DD'))).format('yyyy/MM/DD'), dateTo: moment(new Date()).format('yyyy/MM/DD') })

      setBackOpen(false)
    }
    getData()
  }, [])

  const getPreMonth = (date) => {
    console.log('date', date)
    let arr = date.split('-')
    let year = arr[0] // 获取当前日期的年份
    let month = arr[1] // 获取当前日期的月份
    let day = arr[2] // 获取当前日期的日
    let days = new Date(year, month, 0)
    days = days.getDate() // 获取当前日期中月的天数
    let year2 = year
    let month2 = parseInt(month) - 1
    if (month2 === 0) {
      year2 = parseInt(year2) - 1
      month2 = 12
    }
    let day2 = day
    let days2 = new Date(year2, month2, 0)
    days2 = days2.getDate()
    if (day2 > days2) {
      day2 = days2
    }
    if (month2 < 10) {
      month2 = '0' + month2
    }
    let t2 = year2 + '-' + month2 + '-' + day2
    return t2
  }

  const handleVendorList = (_event, data) => {
    setBackOpen(true)
    const getSub = async () => {
      if (data !== null) {
        setVendorId(data.id)
        setVendorDesc(data.text)
        setVendor(data)
        setSendData({
          ...sendData,
          vendorId: data.id,
          vendorDesc: data.text,
          vendor: data,
        })

        let getSiteList = await apiAtt.getFillSiteWithVendor({
          vendorId: data.id,
        })
        if (getSiteList) {
          setSiteList(getSiteList)
        }
      } else {
        setVendorId(null)
        setVendorDesc(null)
        setVendor(null)
        setSendData({
          ...sendData,
          vendorId: null,
          vendorDesc: null,
          vendor: null,
        })
      }
      setBackOpen(false)
    }
    getSub()
    //
  }

  const handleSiteId = (_event, data) => {
    setBackOpen(true)
    const getSub = async () => {
      if (data !== null) {
        // let getVendorDesc = await api.getVendorDesc()
        let getSubConList = await api.getSubContractorList(data.id)
        if (getSubConList) {
          setSubList(getSubConList)
        }
        // if (getVendorDesc) {
        //   setVendorDesc(getVendorDesc)
        // }
        setSendData({
          ...sendData,
          siteId: data.id,
          siteDesc: data.text,
          site: data,
          subcontractorId: '',
        })
      } else {
        setSendData({
          ...sendData,
          siteId: null,
          siteDesc: null,
          site: null,
          subcontractorId: '',
        })
      }
      setBackOpen(false)
    }
    getSub()
  }

  const handleSubcontractors = (data) => {
    setSendData({
      ...sendData,
      subcontractorId: data.id,
      subcontractorDesc: data.text,
    })
  }

  const handleDateFrom = (e) => {
    setSendData({
      ...sendData,
      dateFrom: moment(new Date(e.target.value).getTime()).format('yyyy/MM/DD'),
      dateTo: '',
    })
  }

  const handleDateTo = (e) => {
    setSendData({
      ...sendData,
      dateTo: moment(new Date(e.target.value).getTime()).format('yyyy/MM/DD'),
    })
  }

  const handleSmartCard = (e, data) => {
    setSendData({ ...sendData, smartCardObj: data, smartCardId: data?.id })
    console.log('smart', sendData)
  }

  const handleCwraNo = (e) => {
    setSendData({ ...sendData, cwraNo: e.target.value })
  }

  const totalHandle = (row) => {
    let resultTotal = 0
    if (row.length > 0)
      tableData.map((row, ind1) => {
        resultTotal = row.total
        return []
      })
    return resultTotal
  }

  const handleEnName = (e) => {
    setSendData({
      ...sendData,
      enName: e.target.value,
      englishName: e.target.value,
    })
  }

  const handleCnName = (e) => {
    setSendData({
      ...sendData,
      cnName: e.target.value,
      chineseName: e.target.value,
    })
  }

  const handleTOC = (e) => {
    setSendData({ ...sendData, toc: e.target.value })
  }

  const handleFace = (e) => {
    setSendData({ ...sendData, face: e.target.value })
  }

  const exportExcel = (data) => {
    $download(`/attendance/report/getPersonalEnterListWithVendorExcel`, 'application/vnd.ms-excel', data)
  }

  const exportPDF = (data) => {
    $download(`/attendance/report/getPersonalEnterListWithVendorPdf`, 'application/pdf', data)
  }

  const handleSubmit = (e) => {
    // if (!sendData.vendor) {
    //   alert('請選擇承判商！！！')
    //   return
    // }
    // if (!sendData.siteId) {
    //   alert('請選擇地盤！！！')
    //   return
    // }

    if (!sendData.dateFrom) {
      alert('請選擇日期（由）！！！')
      return
    }

    if (!sendData.dateTo) {
      alert('請選擇日期（至）！！！')
      return
    }

    if (
      moment(moment(sendData.dateFrom).format('YYYY-MM-DD')).isBefore(moment(new Date('2000-01-01')).format('YYYY-MM-DD')) ||
      moment(moment(sendData.dateTo).format('YYYY-MM-DD')).isAfter(moment(new Date('2999-12-31')).format('YYYY-MM-DD'))
    ) {
      alert('日期超過搜索範圍!!!')
      return
    }

    let fromDate = moment(sendData.dateFrom, 'YYYY/MM/DD')
    let toDate = moment(sendData.dateTo, 'YYYY/MM/DD')

    if (fromDate > toDate) {
      alert('日期（由） 不能大於 日期（至） !!!')
      return
    }

    const getData = async () => {
      setBackOpen(true)
      console.log('sendData', sendData)
      let send = await api.getPersonalReportWithVendor(sendData)
      if (send) {
        setIsSearchPage(false)
        setTableData(send)
      }
      setBackOpen(false)

      console.log('senddata', sendData)
    }
    getData()
  }
  const resetForm = (e) => {
    setSendData({})
  }

  const ReportTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: palette.report.header,
      // color: palette.common.white,
      fontSize: '15px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }))

  const ReportTableRow = styled(TableRow)(({ theme }) => ({
    // "&:nth-of-type(odd)": {
    //   backgroundColor: palette.report.record,
    // },
  }))

  return (
    // 承判商工人個人出入記錄
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        {isSearchPage && (
          <div>
            <form>
              <Container maxWidth="md">
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4" style={{ textAlign: 'center' }}>
                      <b>{t('icon1_personalentersearch_title_withVendor')}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      value={sendData.vendor || null}
                      onChange={handleVendorList}
                      fullWidth
                      size="small"
                      id="combo-box-demo"
                      // error={!sendData.vendor}
                      getOptionLabel={(option) => {
                        // console.log("option", option);
                        return option.text
                      }}
                      options={vendorList}
                      renderInput={(params) => <TextField fullWidth size="small" {...params} label={t('Vendor')} />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      value={sendData.site || null}
                      onChange={handleSiteId}
                      fullWidth
                      size="small"
                      id="combo-box-demo"
                      // error={!sendData.site}
                      getOptionLabel={(option) => {
                        // console.log("option", option);
                        return option.text
                      }}
                      options={siteList}
                      renderInput={(params) => <TextField fullWidth size="small" {...params} label={t('Site_Name')} />}
                    />
                  </Grid>
                  {/* <Grid item xs={6}>
                    <TextField error={!sendData.siteId} id="siteDesc" value={sendData.siteId || ''} fullWidth size="small" label={t('Site_Name')} select>
                      <MenuItem value="" onClick={() => handleSiteId({ id: null, text: 'All' })}>
                        None
                      </MenuItem>
                      {siteList.map((item, index) => (
                        <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleSiteId(item)}>
                          {item.id} - {item.text}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid> */}
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: '2000-12-31',
                        max: '2099-12-31',
                      }}
                      value={moment(new Date(sendData.dateFrom).getTime()).format('yyyy-MM-DD') || ''}
                      fullWidth
                      size="small"
                      label={t('date_from')}
                      type="date"
                      error={
                        !sendData.dateFrom ||
                        moment(moment(sendData.dateFrom).format('YYYY-MM-DD')).isBefore(moment(new Date('2000-01-01')).format('YYYY-MM-DD')) ||
                        moment(moment(sendData.dateFrom).format('YYYY-MM-DD')).isAfter(moment(new Date('2999-12-31')).format('YYYY-MM-DD'))
                      }
                      onChange={handleDateFrom}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: '2099-12-31',
                        min: moment(new Date(sendData.dateFrom).getTime()).format('yyyy-MM-DD') || '',
                      }}
                      value={moment(new Date(sendData.dateTo).getTime()).format('yyyy-MM-DD') || ''}
                      fullWidth
                      size="small"
                      label={t('date_to')}
                      type="date"
                      error={
                        !sendData.dateTo ||
                        moment(moment(sendData.dateTo).format('YYYY-MM-DD')).isAfter(moment(new Date('2999-12-31')).format('YYYY-MM-DD')) ||
                        moment(moment(sendData.dateTo).format('YYYY-MM-DD')).isBefore(moment(new Date('2000-01-01')).format('YYYY-MM-DD'))
                      }
                      onChange={handleDateTo}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField id="subcontractorDesc" value={sendData.subcontractorId || ''} fullWidth size="small" label={t('Subcontractors_(direct employers)')} select>
                      <MenuItem value="" onClick={() => handleSubcontractors({ id: null, text: 'All' })}>
                        None
                      </MenuItem>
                      {subList.map((item, index) => (
                        <MenuItem key={index} value={item.id} onClick={() => handleSubcontractors(item)}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      value={sendData.smartCardObj || null}
                      onChange={handleSmartCard}
                      fullWidth
                      size="small"
                      id="combo-box-demo"
                      // getOptionSelected={(option, value) => {
                      //   return option.id
                      // }}
                      getOptionLabel={(option) => {
                        return option.text
                      }}
                      options={smartCard}
                      renderInput={(params) => <TextField fullWidth size="small" {...params} label={t('icon1_useraccesslog_smart_card_label')} />}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField value={sendData.cwraNo || ''} fullWidth size="small" label={t('icon1_useraccesslog_cwra_card_no_label')} onChange={handleCwraNo} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField value={sendData.englishName || ''} fullWidth size="small" label={t('icon1_useraccesslog_eng_name_label')} onChange={handleEnName} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField value={sendData.chineseName || ''} fullWidth size="small" label={t('icon1_useraccesslog_cn_name_label')} onChange={handleCnName} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField value={sendData.toc || ''} fullWidth size="small" label={t('icon1_useraccesslog_template_on_card_label')} select onChange={handleTOC}>
                      <MenuItem value={null}>None</MenuItem>
                      <MenuItem value="N">N</MenuItem>
                      <MenuItem value="Y">Y</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField value={sendData.face || ''} fullWidth size="small" label={t('icon1_useraccesslog_face_label')} select onChange={handleFace}>
                      <MenuItem value={null}>None</MenuItem>
                      <MenuItem value="N">N</MenuItem>
                      <MenuItem value="Y">Y</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6} />
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <FormControl size="small">
                      <Button
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{
                          backgroundColor: 'primaryBlue',
                          color: 'commom.white',
                        }}
                      >
                        {t('button_submit')}
                      </Button>
                    </FormControl>

                    <FormControl size="small">
                      <Button
                        variant="contained"
                        onClick={resetForm}
                        label="Reset"
                        sx={{
                          backgroundColor: 'primaryGreen',
                          color: 'commom.white',
                          marginLeft: '20px',
                        }}
                      >
                        {t('button_reset')}
                      </Button>
                    </FormControl>
                  </Grid>
                </Grid>
              </Container>
            </form>
          </div>
        )}
      </Container>
      {!isSearchPage && (
        <Container maxWidth="false">
          <div
            style={{
              display: 'block',
              textAlign: 'left',
              marginTop: '0px',
            }}
          >
            <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
              <ArrowBackIcon />
              {t('button_back')}
            </Button>
          </div>

          <div style={{ display: 'block', textAlign: 'right' }}>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportExcel(sendData)}>
              <DownloadIcon />
              {t('button_export_excel')}
            </Button>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportPDF(sendData)}>
              <DownloadIcon />
              {t('button_export_pdf')}
            </Button>
          </div>
          <div ref={printComponentRef}>
            <Typography variant="h4" style={{ textAlign: 'center' }}>
              <b>{t('icon1_personalentersearch_title_withVendor')}</b>
            </Typography>
            <TableContainer>
              <Table sx={{ textAlign: 'left' }} size="small">
                <TableBody>
                  {!!sendData.vendorId && (
                    <TableRow>
                      <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('Vendor')}:</TableCell>
                      <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.vendorDesc}</TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    {/* 地盤名稱 */}
                    <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('Site_Name')}:</TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.siteDesc}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 分判商: 	 */}
                    <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('Subcontractors')}：</TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.subcontractorDesc ? sendData.subcontractorDesc : 'All'}</TableCell>
                  </TableRow>
                  {/* 日期（由) */}
                  {!sendData.dateFrom ||
                    (sendData.dateFrom !== null && (
                      <TableRow>
                        <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('date_from')}:</TableCell>
                        <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.dateFrom}</TableCell>
                      </TableRow>
                    ))}

                  {!sendData.dateTo ||
                    (sendData.dateFrom !== null && (
                      <TableRow>
                        {/* 日期（至) */}
                        <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('date_to')}:</TableCell>
                        <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.dateTo}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            {!!tableData &&
              tableData.length > 0 &&
              tableData.map((row1, ind1) => (
                <div key={ind1}>
                  <div>
                    <TableContainer sx={{ maxHeight: '100vh', maxWidth: '100vw' }}>
                      <ul style={{ listStyleType: 'none', fontSize: '15' }}>
                        <li>{t('icon1_personalentersearch_smart_card_no_column') + row1.smartCardId}</li>
                        <li>{t('icon1_personalentersearch_eng_name_column') + row1.englishName}</li>
                        <li>{t('icon1_personalentersearch_cn_name_column') + row1.chineseName}</li>

                        <li>{t('icon1_personalentersearch_template_on_card_column') + ': ' + row1.templateOnCard}</li>

                        <li>{t('FACE') + ': ' + row1.faceId}</li>
                      </ul>
                      <Table size="small" sx={{ mt: '15px' }} stickyHeader>
                        <TableHead>
                          <ReportTableRow>
                            <ReportTableCell align="left">{t('Subcontractors_(direct employers)')}</ReportTableCell>
                            <ReportTableCell align="left">{t('icon1_personalentersearch_access_date_column')}</ReportTableCell>
                            <ReportTableCell align="left">{t('icon1_personalentersearch_time_column')}</ReportTableCell>
                            <ReportTableCell align="left">{t('icon1_personalentersearch_work_type_column')}</ReportTableCell>
                            <ReportTableCell align="left">{t('icon1_personalentersearch_access_column')}</ReportTableCell>
                            <ReportTableCell align="left">{t('icon1_personalentersearch_access_condition_column')}</ReportTableCell>
                          </ReportTableRow>
                        </TableHead>
                        {!!row1.personalEnterListResultVo && row1.personalEnterListResultVo.length > 0 && (
                          <TableBody>
                            {row1.personalEnterListResultVo.map((row2, ind2) => (
                              <ReportTableRow
                                hover
                                key={ind2}
                                sx={{
                                  backgroundColor: ind2 % 2 !== 0 ? '#DCDCDC' : '#ffffff',
                                }}
                              >
                                <ReportTableCell component="th" scope="row">
                                  {row2.vendorDesc}
                                </ReportTableCell>
                                <ReportTableCell align="left">{row2.accessDate}</ReportTableCell>
                                <ReportTableCell align="left">{row2.accessTime}</ReportTableCell>
                                <ReportTableCell align="left">{row2.jobNatureDesc}</ReportTableCell>
                                <ReportTableCell align="left">{row2.inout}</ReportTableCell>
                                <ReportTableCell align="left">{row2.statusDesc}</ReportTableCell>
                              </ReportTableRow>
                            ))}
                          </TableBody>
                        )}

                        {(!tableData || tableData.length === 0) && (
                          <TableBody>
                            <ReportTableRow>
                              <ReportTableCell colSpan={6}>{'No data found!'}</ReportTableCell>
                            </ReportTableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                    <label>
                      {t('icon1_personalentersearch_personal_access_times_column') + row1.personalEnterListResultVo.length}
                      <ul style={{ listStyleType: 'none', fontSize: '15' }}>
                        <li> &nbsp;</li>
                      </ul>
                    </label>
                    <Divider />
                  </div>
                </div>
              ))}
            <label>
              {t('icon1_personalentersearch_sub_worker_access_times_column') + totalHandle(tableData)}
              <br />
            </label>
          </div>

          <label style={{ fontSize: '15' }}>{t('Remark')}</label>
          <ul style={{ listStyleType: 'none', fontSize: '15' }}>
            <li>{t('icon1_personalentersearch_remark1')}</li>
            <li>{t('icon1_personalentersearch_remark2')}</li>
            <li>{t('icon1_personalentersearch_remark3')}</li>
            <li>{t('icon1_personalentersearch_remark4')}</li>
            <li>{t('icon1_personalentersearch_remark5')}</li>
            <li>{t('icon1_personalentersearch_remark6')}</li>
          </ul>
        </Container>
      )}
    </>
  )
}

const ddlSiteList = [
  { val: '212-TEXRD', text: '212-TEXRD - 212 Texaco Road' },
  { val: '228-WCR', text: '228-WCR - 222-228 Wan Chai Road' },
  { val: '233-PERW', text: '233-PERW - 233 Prince Edward RD West' },
]

const ddlSubList = [
  { val: 'NIL: NIL', text: 'NIL: NIL' },
  {
    val: '全記(香港)建築工程有限公司-馮全 / 馮其臻',
    text: '全記(香港)建築工程有限公司-馮全 / 馮其臻',
  },
]

const ddlSmartCardList = [
  { val: '1000003780', text: '1000003780' },
  { val: '1000003790', text: '1000003790' },
]

const ddlTemplateOnCardList = [
  { val: 'Y', text: 'Y' },
  { val: 'N', text: 'N' },
]

const ddlFaceList = [
  { val: 'Y', text: 'Y' },
  { val: 'N', text: 'N' },
]

let tableData = {
  dataList: [
    {
      p1: '1000011221',
      p2: 'CWR06035108',
      p3: '林瑞明',
      p4: 'LAM SHUI MING',
      p5: 'Y',
      p6: 'Y',
      details: [
        {
          p7: 'KWONG LUEN ENGINEERING LTD:關翠玲',
          p8: '2021/05/03',
          p9: '07:38',
          p10: '坭井',
          p11: '入',
          p12: 'Active',
        },
        {
          p7: 'KWONG LUEN ENGINEERING LTD:關翠玲',
          p8: '2021/05/03',
          p9: '07:38',
          p10: '坭井',
          p11: '入',
          p12: 'Active',
        },
        {
          p7: 'KWONG LUEN ENGINEERING LTD:關翠玲',
          p8: '2021/05/03',
          p9: '07:38',
          p10: '坭井',
          p11: '入',
          p12: 'Active',
        },
        {
          p7: 'KWONG LUEN ENGINEERING LTD:關翠玲',
          p8: '2021/05/03',
          p9: '07:38',
          p10: '坭井',
          p11: '入',
          p12: 'Active',
        },
        {
          p7: 'KWONG LUEN ENGINEERING LTD:關翠玲',
          p8: '2021/05/03',
          p9: '07:38',
          p10: '坭井',
          p11: '入',
          p12: 'Active',
        },
        {
          p7: 'KWONG LUEN ENGINEERING LTD:關翠玲',
          p8: '2021/05/03',
          p9: '07:38',
          p10: '坭井',
          p11: '入',
          p12: 'Active',
        },
        {
          p7: 'KWONG LUEN ENGINEERING LTD:關翠玲',
          p8: '2021/05/03',
          p9: '07:38',
          p10: '坭井',
          p11: '入',
          p12: 'Active',
        },
        {
          p7: 'KWONG LUEN ENGINEERING LTD:關翠玲',
          p8: '2021/05/03',
          p9: '07:38',
          p10: '坭井',
          p11: '入',
          p12: 'Active',
        },
        {
          p7: 'KWONG LUEN ENGINEERING LTD:關翠玲',
          p8: '2021/05/03',
          p9: '07:38',
          p10: '坭井',
          p11: '入',
          p12: 'Active',
        },
        {
          p7: 'KWONG LUEN ENGINEERING LTD:關翠玲',
          p8: '2021/05/03',
          p9: '07:38',
          p10: '坭井',
          p11: '入',
          p12: 'Active',
        },
      ],
      total: 10,
    },
  ],
  total: 10,
}

import { React, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Autocomplete,
  Container,
  Grid,
  FormControl,
  Button,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  TextField,
  MenuItem,
} from '@mui/material'
import dayjs from 'dayjs'
import { parseInt } from 'lodash'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import moment from 'moment'
import TablePagination from '@mui/material/TablePagination'
import $http, { $download } from 'src/utils/api/services'
import { Today } from '@mui/icons-material'
import * as apiAtt from 'src/utils/api/workerAttendanceRecord/AutoPayApiAttend'
import * as api from '../../../utils/api/workerAttendanceRecord/AccRecSearchApi'

export default function AccRecSearchWithVendor() {
  const [isSearchPage, setIsSearchPage] = useState(true)
  const { t } = useTranslation()
  const [backOpen, setBackOpen] = useState(false)
  const [siteList, setSiteList] = useState([])
  const [subList, setSubList] = useState([])
  const [smartCard, setSmartCard] = useState([])
  const [sendData, setSendData] = useState({})
  const [tableData, setTableData] = useState([])
  const [vendorList, setVendorList] = useState([])
  const [orderType, setOrderType] = useState('ASC')
  const [defaultOrderBy, setDefaultOrderBy] = useState({})
  const [vendorDesc, setVendorDesc] = useState({})
  const [vendorDescN, setVendorDescN] = useState('')
  const [vendorId, setVendorId] = useState('')
  const [vendor, setVendor] = useState({})
  const [site, setSite] = useState({})
  const [rowsPerPage, setRowsPerPage] = useState(50) // 每页显示的行数
  const [page, setPage] = useState(0) // 当前页

  const ddlDLTypeList = [
    { val: 'Screen', text: 'Screen' },
    { val: 'Excel', text: 'Excel' },
    { val: 'SPDF', text: 'PDF' },
  ]
  const rgOrderList = [
    { val: 'ASC', text: 'ASC' },
    { val: 'DESC', text: 'DESC' },
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    if (!Number.isNaN(newRowsPerPage) && newRowsPerPage > 0) {
      // 使用 Number.isNaN
      setRowsPerPage(newRowsPerPage)
      setPage(0) // 重置到第一页
    }
  }

  // 计算当前页要显示的数据
  const paginatedData = tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  const [totalCount, setTotalCount] = useState(0) // 总数据条数

  useEffect(() => {
    setBackOpen(true)
    const getData = async () => {
      let getFillSiteWithVendorAll = await apiAtt.getFillSiteWithVendorAll()
      if (getFillSiteWithVendorAll) {
        setVendorList(getFillSiteWithVendorAll)
      }
      setSendData({
        ...sendData,
        downloadType: 'Screen',
        orderBy: 'accessDate',
        dateFrom: moment(getPreMonth(moment(new Date()).format('yyyy-MM-DD'))).format('yyyy/MM/DD'),
        dateTo: moment(new Date()).format('yyyy/MM/DD'),
      })
      setDefaultOrderBy('accessDate')
      setBackOpen(false)
    }
    getData()
  }, [])

  const getPreMonth = (date) => {
    console.log('date', date)
    let arr = date.split('-')
    let year = arr[0] // 获取当前日期的年份
    let month = arr[1] // 获取当前日期的月份
    let day = arr[2] // 获取当前日期的日
    let days = new Date(year, month, 0)
    days = days.getDate() // 获取当前日期中月的天数
    let year2 = year
    let month2 = parseInt(month) - 1
    if (month2 === 0) {
      year2 = parseInt(year2) - 1
      month2 = 12
    }
    let day2 = day
    let days2 = new Date(year2, month2, 0)
    days2 = days2.getDate()
    if (day2 > days2) {
      day2 = days2
    }
    if (month2 < 10) {
      month2 = '0' + month2
    }
    let t2 = year2 + '-' + month2 + '-' + day2
    return t2
  }

  const handleBack = () => {
    setIsSearchPage(true)
    setPage(0)
  }

  const handleVendorList = (_event, data) => {
    setBackOpen(true)
    const getSub = async () => {
      if (data !== null) {
        setVendorId(data.id)
        setVendorDescN(data.text)
        setVendor(data)
        setSendData({
          ...sendData,
          vendorId: data.id,
          vendorDesc: data.text,
          vendor: data,
        })

        let getSiteList = await apiAtt.getFillSiteWithVendor({
          vendorId: data.id,
        })
        if (getSiteList) {
          setSiteList(getSiteList)
        }
        console.log('data', data)
        let getSmartCard = await api.getSmartCardByVendor({ vendorId: data.id })
        if (getSmartCard) {
          setSmartCard(getSmartCard)
        }
      } else {
        setVendorId(null)
        setVendorDesc(null)
        setVendor(null)
        setSendData({
          ...sendData,
          vendorId: null,
          vendorDesc: null,
          vendor: null,
        })
      }
      setBackOpen(false)
    }
    getSub()
    //
  }

  // const handleSiteId = (data) => {
  //   setBackOpen(true)
  //   const getSub = async () => {
  //     let getSubConList = await api.getSubContractorList(data.id)
  //     let getVendorDesc = await api.getVendorDesc()
  //     if (getVendorDesc) {
  //       setVendorDesc(getVendorDesc)
  //     }
  //     if (getSubConList) {
  //       setSubList(getSubConList)
  //     }
  //     setSendData({
  //       ...sendData,
  //       siteId: data.id,
  //       siteDesc: data.text,
  //       subContractorId: '',
  //     })
  //     setBackOpen(false)
  //   }
  //   getSub()
  // }
  const handleSiteId = (_event, data) => {
    setBackOpen(true)
    const getSub = async () => {
      if (data !== null) {
        console.log('sendData', sendData)
        let getVendorDesc = await api.getVendorDesc()
        if (getVendorDesc) {
          setVendorDesc(getVendorDesc)
        }

        setSendData({
          ...sendData,
          siteId: data.id,
          siteDesc: data.text,
          site: data,
          subContractorId: '',
        })
        let getSubConList = await api.getSubContractorIdWitheVendor(data.id, sendData.vendorId)
        if (getSubConList) {
          setSubList(getSubConList)
        }
      } else {
        setSendData({
          ...sendData,
          siteId: null,
          siteDesc: null,
          site: null,
          subContractorId: '',
        })
      }
      setBackOpen(false)
    }
    getSub()
  }

  const handleSubcontractors = (data) => {
    setSendData({
      ...sendData,
      subContractorId: data.id,
      subContractorDesc: data.text,
    })
    console.log('xx', data)
  }

  const handleDateFrom = (e) => {
    setSendData({
      ...sendData,
      dateFrom: moment(new Date(e.target.value).getTime()).format('yyyy/MM/DD'),
      dateTo: '',
    })
  }

  const handleDateTo = (e) => {
    setSendData({
      ...sendData,
      dateTo: moment(new Date(e.target.value).getTime()).format('yyyy/MM/DD'),
    })
  }

  const handleSmartCard = (e, data) => {
    setSendData({ ...sendData, smartCardObj: data, smartCard: data?.id })
  }

  const handleCwraNo = (e) => {
    setSendData({ ...sendData, cwraNo: e.target.value })
  }

  const handleEnName = (e) => {
    setSendData({ ...sendData, enName: e.target.value })
  }

  const handleCnName = (e) => {
    setSendData({ ...sendData, cnName: e.target.value })
  }

  const handleTOC = (e) => {
    setSendData({ ...sendData, toc: e.id })
    console.log('asas', e)
  }

  const handleFace = (e) => {
    setSendData({ ...sendData, face: e.id })
  }

  const handleOrderBy = (e) => {
    setDefaultOrderBy(e.id)
    setSendData({ ...sendData, orderBy: e.id })
    console.log('order by ', e.id)
  }

  const handleAscOrder = (e) => {
    setSendData({ ...sendData, ascOrder: e.target.value })
  }

  const handleOrderType = (e) => {
    setOrderType(e.target.value)
    setSendData({ ...sendData, ascOrder: orderType === 'ASC' })
  }

  const handleDownloadType = (e) => {
    setSendData({ ...sendData, downloadType: e.id })
  }

  // 检查日期是否有效的函数
  const isInvalidDate = (dateString) => {
    // 尝试将字符串转换为日期对象
    const date = new Date(dateString)
    // 检查日期对象是否有效
    return Number.isNaN(date.getTime()) || dateString === 'Invalid date'
  }

  const handleSubmit = (e) => {
    console.log('sendData', sendData)
    const getData = async () => {
      // if (!sendData.vendor) {
      //   alert('請選擇承判商！！！')
      //   return
      // }
      // if (!sendData.siteId) {
      //   alert('請選擇地盤！！！')
      //   return
      // }
      if (!sendData.dateFrom || isInvalidDate(sendData.dateFrom)) {
        alert('請選擇日期（由）！！！')
        return
      }
      if (!sendData.dateTo || isInvalidDate(sendData.dateTo)) {
        alert('請選擇日期（至）！！！')
        return
      }

      let fromDate = moment(sendData.dateFrom, 'YYYY/MM/DD')
      let toDate = moment(sendData.dateTo, 'YYYY/MM/DD')

      if (fromDate > toDate) {
        alert('日期（由） 不能大於 日期（至）!')

        return
      }
      if (toDate.diff(fromDate, 'months') >= 3) {
        alert(t('select_over_three_month'))
        return
      }

      if (sendData.downloadType === 'Screen') {
        setBackOpen(true)
        let send = await api.getReportWithVendor(sendData)

        if (send) {
          setIsSearchPage(false)
          setTableData(send || [])
          setTotalCount(send.length || 0) // 假设总数在 totalCount 中
        }
      }

      if (sendData.downloadType === 'Excel') {
        setBackOpen(true)
        exportExcel(sendData)
      } else if (sendData.downloadType === 'PDF') {
        setBackOpen(true)
        exportPDF(sendData)
      }
      setBackOpen(false)
    }

    getData()
  }

  const resetForm = (e) => {
    // setSendData({ downloadType: 'Screen', ascOrder: true, orderBy: 'englishName' })
    // setOrderType('DESC')

    setOrderType('ASC')
    setSendData({ downloadType: 'Screen', ascOrder: true, orderBy: 'englishName' })
  }

  const exportExcel = (data) => {
    $download(`/attendance/report/getAccRecListWithVendorExcel`, 'application/vnd.ms-excel', data)
  }

  const exportPDF = (data) => {
    $download(`/attendance/report/getAccRecListWithVendorPdf`, 'application/pdf', data)
  }

  // // Helper functions
  // const renderAutocomplete = (id, value, onChange, options, label, xs) => (
  //   <Grid item xs={xs}>
  //     <Autocomplete
  //       value={value || null}
  //       onChange={onChange}
  //       fullWidth
  //       size="small"
  //       id={`combo-box-${id}`}
  //       getOptionLabel={(option) => option.text}
  //       options={options}
  //       renderInput={(params) => <TextField fullWidth size="small" {...params} label={label} />}
  //     />
  //   </Grid>
  // )

  const renderAutocomplete = (id, value, onChange, options, label, xs) => {
    // 去重选项
    const uniqueOptions = Array.from(new Set(options.map((option) => option.text))).map((text) => options.find((option) => option.text === text))

    return (
      <Grid item xs={xs}>
        <Autocomplete
          value={value || null}
          onChange={onChange}
          fullWidth
          size="small"
          id={`combo-box-${id}`}
          getOptionLabel={(option) => option.text}
          options={uniqueOptions} // 使用去重后的选项
          renderInput={(params) => <TextField fullWidth size="small" {...params} label={label} />}
        />
      </Grid>
    )
  }

  const renderDateField = (id, value, onChange, label, xs, minDate) => (
    <Grid item xs={xs}>
      <TextField
        InputLabelProps={{ shrink: true }}
        inputProps={{ max: '2099-12-31', min: minDate }}
        value={moment(new Date(value).getTime()).format('yyyy-MM-DD') || ''}
        fullWidth
        size="small"
        label={label}
        type="date"
        error={!value}
        onChange={onChange}
      />
    </Grid>
  )

  // const renderSelectField = (id, value, onChange, options, label, xs, defaultValue) => (
  //   <Grid item xs={xs}>
  //     <TextField id={id} value={value || ''} fullWidth size="small" label={label} select onChange={onChange}>
  //       {options.map((item, index) => (
  //         <MenuItem key={index} value={item.id} onClick={() => onChange(item)}>
  //           {item.text}
  //         </MenuItem>
  //       ))}
  //     </TextField>
  //   </Grid>
  // )

  const renderSelectField = (id, value, onChange, options, label, xs, defaultValue) => (
    <Grid item xs={xs}>
      <TextField
        id={id}
        value={value || ''} // 确保 value 为字符串
        fullWidth
        size="small"
        label={label}
        select
        onChange={(event) => {
          const selectedValue = event.target.value // 获取选中的值
          const selectedItem = options.find((item) => item.id === selectedValue) // 找到对应的选项
          onChange(selectedItem) // 调用 onChange 更新状态
        }}
      >
        {options.map((item, index) => (
          <MenuItem key={index} value={item.id}>
            {item.text}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  )

  const renderTextField = (id, value, onChange, label, xs) => (
    <Grid item xs={xs}>
      <TextField value={value || ''} fullWidth size="small" label={label} onChange={onChange} />
    </Grid>
  )

  const renderRadioGroup = (name, value, onChange, xs) => (
    <Grid item xs={xs}>
      <FormControl size="small">
        <RadioGroup row onChange={onChange} value={value}>
          <FormControlLabel value="ASC" label="ASC" control={<Radio />} />
          <FormControlLabel value="DESC" label="DESC" control={<Radio />} />
        </RadioGroup>
      </FormControl>
    </Grid>
  )

  const renderTable = (sendData, tableData) => (
    <div>
      <Typography variant="h4" style={{ textAlign: 'center', mt: '-30px' }}>
        <b>{t('icon1_accrecsearch_title_vendor')}</b>
      </Typography>
      <TableContainer>
        <Table padding="none" sx={{ textAlign: 'left', mt: '5px' }} size="small">
          <TableBody>
            {!!sendData.vendorId && (
              <TableRow>
                <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('Vendor')}:</TableCell>
                <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.vendorDesc}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell sx={{ textAlign: 'left', display: 'block', borderBottom: 'none' }}>{t('Site_Name')}:</TableCell>
              <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.siteDesc || 'All'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontSize: '15px', width: 120, borderBottom: 'none' }}>{t('Subcontractors')}：</TableCell>
              <TableCell sx={{ fontSize: '15px', textAlign: 'left', borderBottom: 'none' }}>{sendData.subContractorDesc || 'All'}</TableCell>
            </TableRow>
            {!!sendData.dateFrom && (
              <TableRow>
                <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('date_from')}:</TableCell>
                <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.dateFrom}</TableCell>
              </TableRow>
            )}
            {!!sendData.dateTo && (
              <TableRow>
                <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('date_to')}:</TableCell>
                <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.dateTo}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('icon1_accrecsearch_total_condition')}：</TableCell>
              <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>
                <Typography sx={{ display: 'block', fontSize: '15px' }}>{totalCount || 0}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ maxHeight: '80vh', maxWidth: '100vw' }}>
        <Table size="small" sx={{ mt: '20px' }} stickyHeader>
          <TableHead>
            <TableRow>
              {[
                'Contractor',
                'Subcontractors_(direct employers)',
                'icon1_accrecsearch_smart_card_no_column',
                'icon1_accrecsearch_cwra_card_no_column',
                'icon1_accrecsearch_cn_name_column',
                'icon1_accrecsearch_eng_name_column',
                'icon1_accrecsearch_template_on_card_column',
                'FACE',
                'date',
                'Gate_time',
                'Exit_time',
              ].map((header, index) => (
                <TableCell key={index} sx={{ fontSize: '15px', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                  {t(header)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.length > 0 ? (
              tableData.map((row, ind) => (
                <TableRow hover key={ind} sx={{ backgroundColor: ind % 2 !== 0 ? '#DCDCDC' : '#ffffff' }}>
                  <TableCell>{row.vendorDesc}</TableCell>
                  <TableCell>{row.subContractorDesc}</TableCell>
                  <TableCell>{row.smartCardId}</TableCell>
                  <TableCell>{row.cwraCardNo}</TableCell>
                  <TableCell>{row.chineseName}</TableCell>
                  <TableCell>{row.englishName}</TableCell>
                  <TableCell>{row.templateOnCard}</TableCell>
                  <TableCell>{row.faceId}</TableCell>
                  <TableCell>{row.accessDate}</TableCell>
                  <TableCell>{row.accessTimeIn}</TableCell>
                  <TableCell>{row.accessTimeOut}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell sx={{ textAlign: 'center' }} colSpan={10}>
                  {'No data found !'}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )

  // return (
  //   <>
  //     <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
  //       <CircularProgress color="inherit" />
  //     </Backdrop>

  //     {isSearchPage && (
  //       <form>
  //         <Container maxWidth="md">
  //           <Grid container justifyContent="center" alignItems="center" spacing={2}>
  //             {/* 承判商工人出入記錄一覽表 */}
  //             <Grid item xs={12}>
  //               <Typography variant="h4" style={{ textAlign: 'center' }}>
  //                 <b>{t('icon1_accrecsearch_title_vendor')}</b>
  //               </Typography>
  //             </Grid>
  //             <Grid item xs={6}>
  //               <Autocomplete
  //                 value={sendData.vendor || null}
  //                 onChange={handleVendorList}
  //                 fullWidth
  //                 size="small"
  //                 id="combo-box-demo"
  //                 // error={!sendData.vendor}
  //                 getOptionLabel={(option) => {
  //                   // console.log("option", option);
  //                   return option.text
  //                 }}
  //                 options={vendorList}
  //                 renderInput={(params) => <TextField fullWidth size="small" {...params} label={t('Vendor')} />}
  //               />
  //             </Grid>
  //             <Grid item xs={6}>
  //               <Autocomplete
  //                 value={sendData.site || null}
  //                 onChange={handleSiteId}
  //                 fullWidth
  //                 size="small"
  //                 id="combo-box-demo"
  //                 // error={!sendData.site}
  //                 getOptionLabel={(option) => {
  //                   // console.log("option", option);
  //                   return option.text
  //                 }}
  //                 options={siteList}
  //                 renderInput={(params) => <TextField fullWidth size="small" {...params} label={t('Site_Name')} />}
  //               />
  //             </Grid>
  //             {/* <Grid item xs={6}>
  //               <TextField error={!sendData.siteId} id="siteDesc" value={sendData.siteId || ''} fullWidth size="small" label={t('Site_Name')} select>
  //                 <MenuItem value="" onClick={() => handleSiteId({ id: null, text: 'All' })}>
  //                   None
  //                 </MenuItem>
  //                 {siteList.map((item, index) => (
  //                   <MenuItem key={index} value={item.id} name={item.text} onClick={() => handleSiteId(item)}>
  //                     {item.id} - {item.text}
  //                   </MenuItem>
  //                 ))}
  //               </TextField>
  //             </Grid> */}
  //             <Grid item xs={6}>
  //               <TextField
  //                 InputLabelProps={{
  //                   shrink: true,
  //                 }}
  //                 inputProps={{
  //                   max: '2099-12-31',
  //                 }}
  //                 value={moment(new Date(sendData.dateFrom).getTime()).format('yyyy-MM-DD') || ''}
  //                 fullWidth
  //                 size="small"
  //                 label={t('date_from')}
  //                 type="date"
  //                 error={!sendData.dateFrom}
  //                 onChange={handleDateFrom}
  //               />
  //             </Grid>

  //             <Grid item xs={6}>
  //               <TextField
  //                 InputLabelProps={{
  //                   shrink: true,
  //                 }}
  //                 inputProps={{
  //                   max: '2099-12-31',
  //                   min: moment(new Date(sendData.dateFrom).getTime()).format('yyyy-MM-DD') || '',
  //                 }}
  //                 value={moment(new Date(sendData.dateTo).getTime()).format('yyyy-MM-DD') || ''}
  //                 fullWidth
  //                 size="small"
  //                 label={t('date_to')}
  //                 type="date"
  //                 error={!sendData.dateTo}
  //                 // defaultValue={moment(new Date().getTime()).format(
  //                 //   "yyyy-MM-DD"
  //                 // )}
  //                 onChange={handleDateTo}
  //               />
  //             </Grid>

  //             <Grid item xs={6}>
  //               <TextField id="subcontractorDesc" value={sendData.subContractorId || ''} fullWidth size="small" label={t('Subcontractors_(direct employers)')} select>
  //                 <MenuItem value="" onClick={() => handleSubcontractors({ id: null, text: 'All' })}>
  //                   None
  //                 </MenuItem>
  //                 {subList.map((item, index) => (
  //                   <MenuItem key={index} value={item.id} onClick={() => handleSubcontractors(item)}>
  //                     {item.text}
  //                   </MenuItem>
  //                 ))}
  //               </TextField>
  //             </Grid>
  //             <Grid item xs={6}>
  //               <Autocomplete
  //                 value={sendData.smartCardObj || null}
  //                 onChange={handleSmartCard}
  //                 fullWidth
  //                 size="small"
  //                 id="combo-box-demo"
  //                 getOptionLabel={(option) => {
  //                   return option.text
  //                 }}
  //                 options={smartCard}
  //                 renderInput={(params) => <TextField {...params} fullWidth size="small" label={t('icon1_useraccesslog_smart_card_label')} />}
  //               />
  //             </Grid>

  //             <Grid item xs={6}>
  //               <TextField value={sendData.cwraNo || ''} fullWidth size="small" label={t('icon1_useraccesslog_cwra_card_no_label')} onChange={handleCwraNo} />
  //             </Grid>
  //             <Grid item xs={6}>
  //               <TextField value={sendData.enName || ''} fullWidth size="small" label={t('icon1_useraccesslog_eng_name_label')} onChange={handleEnName} />
  //             </Grid>
  //             <Grid item xs={6}>
  //               <TextField value={sendData.cnName || ''} fullWidth size="small" label={t('icon1_useraccesslog_cn_name_label')} onChange={handleCnName} />
  //             </Grid>
  //             <Grid item xs={6}>
  //               <TextField value={sendData.toc || ''} fullWidth size="small" label={t('icon1_useraccesslog_template_on_card_label')} select onChange={handleTOC}>
  //                 <MenuItem value={null}>None</MenuItem>
  //                 <MenuItem value="N">N</MenuItem>
  //                 <MenuItem value="Y">Y</MenuItem>
  //               </TextField>
  //             </Grid>
  //             <Grid item xs={6}>
  //               <TextField value={sendData.face || ''} fullWidth size="small" label={t('icon1_useraccesslog_face_label')} select onChange={handleFace}>
  //                 <MenuItem value={null}>None</MenuItem>
  //                 <MenuItem value="N">N</MenuItem>
  //                 <MenuItem value="Y">Y</MenuItem>
  //               </TextField>
  //             </Grid>

  //             <Grid item xs={6}>
  //               <TextField value={sendData.orderBy || ''} fullWidth size="small" label={t('icon1_useraccesslog_order_label')} select onChange={handleOrderBy} defaultValue={defaultOrderBy}>
  //                 <MenuItem value={null}>None</MenuItem>
  //                 <MenuItem value="accessDate">Access Date</MenuItem>
  //                 <MenuItem value="accessTimeIn">Access Time In</MenuItem>
  //                 <MenuItem value="accessTimeOut">Access Time Out</MenuItem>
  //                 <MenuItem value="chineseName">Chinese Name</MenuItem>
  //                 <MenuItem value="englishName">English Name</MenuItem>
  //                 <MenuItem value="smartCardId">Smart Card Id</MenuItem>
  //               </TextField>
  //             </Grid>

  //             <Grid item xs={6}>
  //               <TextField value={sendData.downloadType || ''} fullWidth size="small" label={t('icon1_useraccesslog_export_type_label')} select onChange={handleDownloadType}>
  //                 <MenuItem value="Screen">Screen</MenuItem>
  //                 <MenuItem value="Excel">Excel</MenuItem>
  //                 <MenuItem value="PDF">PDF</MenuItem>
  //               </TextField>
  //             </Grid>
  //             <Grid item xs={6}>
  //               <FormControl size="small">
  //                 <RadioGroup row onChange={handleOrderType} value={orderType} defaultValue={orderType}>
  //                   <FormControlLabel value="ASC" label="ASC" control={<Radio />} />
  //                   <FormControlLabel value="DESC" label="DESC" control={<Radio />} />
  //                 </RadioGroup>
  //               </FormControl>
  //             </Grid>
  //             <Grid item xs={6} />
  //             <Grid item xs={12} sx={{ textAlign: 'center' }}>
  //               <FormControl>
  //                 <Button
  //                   variant="contained"
  //                   onClick={handleSubmit}
  //                   sx={{
  //                     backgroundColor: 'primaryBlue',
  //                     color: 'commom.white',
  //                   }}
  //                 >
  //                   {t('button_submit')}
  //                 </Button>
  //               </FormControl>
  //               <FormControl sx={{ marginLeft: '20px' }}>
  //                 <Button
  //                   variant="contained"
  //                   onClick={resetForm}
  //                   sx={{
  //                     backgroundColor: 'primaryGreen',
  //                     color: 'commom.white',
  //                     marginRight: '10px',
  //                   }}
  //                   label="Reset"
  //                 >
  //                   {t('button_reset')}
  //                 </Button>
  //               </FormControl>
  //             </Grid>
  //           </Grid>
  //         </Container>
  //       </form>
  //     )}

  //     {!isSearchPage && (
  //       <Container maxWidth="false">
  //         <div
  //           style={{
  //             display: 'block',
  //             textAlign: 'left',
  //             marginTop: '0px',
  //           }}
  //         >
  //           <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
  //             <ArrowBackIcon />
  //             {t('button_back')}
  //           </Button>
  //         </div>

  //         <div style={{ display: 'block', textAlign: 'right' }}>
  //           <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportExcel(sendData)}>
  //             <DownloadIcon />
  //             {t('button_export_excel')}
  //           </Button>
  //           <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportPDF(sendData)}>
  //             <DownloadIcon />
  //             {t('button_export_pdf')}
  //           </Button>
  //         </div>
  //         <div>
  //           <Typography variant="h4" style={{ textAlign: 'center', mt: '-30px' }}>
  //             <b>{t('icon1_accrecsearch_title_vendor')}</b>
  //           </Typography>
  //           <TableContainer>
  //             <Table padding="none" sx={{ textAlign: 'left', mt: '5px' }} size="small">
  //               <TableBody>
  //                 {!!sendData.vendorId && (
  //                   <TableRow>
  //                     <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('Vendor')}:</TableCell>
  //                     <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.vendorDesc}</TableCell>
  //                   </TableRow>
  //                 )}
  //                 <TableRow>
  //                   {/* 地盤名稱 */}
  //                   <TableCell
  //                     sx={{
  //                       textAlign: 'left',
  //                       display: 'block',
  //                       borderBottom: 'none',
  //                     }}
  //                   >
  //                     {t('Site_Name')}:
  //                   </TableCell>
  //                   <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.siteDesc ? sendData.siteDesc : 'All'}</TableCell>
  //                 </TableRow>
  //                 <TableRow>
  //                   {/* 分判商: 	 */}
  //                   <TableCell
  //                     sx={{
  //                       fontSize: '15px',
  //                       width: 120,
  //                       borderBottom: 'none',
  //                     }}
  //                   >
  //                     {t('Subcontractors')}：
  //                   </TableCell>
  //                   <TableCell
  //                     sx={{
  //                       fontSize: '15px',
  //                       textAlign: 'left',
  //                       borderBottom: 'none',
  //                     }}
  //                   >
  //                     {sendData.subContractorDesc ? sendData.subContractorDesc : 'All'}
  //                   </TableCell>
  //                 </TableRow>
  //                 {/* 日期（由) */}
  //                 {!!sendData.dateFrom && (
  //                   <TableRow>
  //                     <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('date_from')}:</TableCell>
  //                     <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.dateFrom}</TableCell>
  //                   </TableRow>
  //                 )}
  //                 {!!sendData.dateTo && (
  //                   <TableRow>
  //                     {/* 日期（至) */}
  //                     <TableCell sx={{ width: 200, borderBottom: 'none' }}>{t('date_to')}:</TableCell>
  //                     <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.dateTo}</TableCell>
  //                   </TableRow>
  //                 )}
  //                 <TableRow>
  //                   {/* 總數: */}
  //                   <TableCell sx={{ display: 'block', borderBottom: 'none' }}>{t('icon1_accrecsearch_total_condition')}：</TableCell>
  //                   <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>
  //                     <Typography sx={{ display: 'block', fontSize: '15px' }}>{tableData && tableData.length}</Typography>
  //                   </TableCell>
  //                 </TableRow>
  //               </TableBody>
  //             </Table>
  //           </TableContainer>
  //           <TableContainer sx={{ maxHeight: '80vh', maxWidth: '100vw' }}>
  //             <Table size="small" sx={{ mt: '20px' }} stickyHeader>
  //               <TableHead>
  //                 <TableRow>
  //                   <TableCell
  //                     sx={{
  //                       fontSize: '15px',
  //                       wordBreak: 'keep-all',
  //                       whiteSpace: 'nowrap',
  //                     }}
  //                   >
  //                     {t('Contractor')}
  //                   </TableCell>
  //                   <TableCell
  //                     sx={{
  //                       fontSize: '15px',
  //                       wordBreak: 'keep-all',
  //                       whiteSpace: 'nowrap',
  //                     }}
  //                   >
  //                     {t('Subcontractors_(direct employers)')}
  //                   </TableCell>
  //                   <TableCell
  //                     sx={{
  //                       fontSize: '15px',
  //                       wordBreak: 'keep-all',
  //                       whiteSpace: 'nowrap',
  //                     }}
  //                   >
  //                     {t('icon1_accrecsearch_smart_card_no_column')}
  //                   </TableCell>
  //                   <TableCell
  //                     sx={{
  //                       fontSize: '15px',
  //                       wordBreak: 'keep-all',
  //                       whiteSpace: 'nowrap',
  //                     }}
  //                   >
  //                     {t('icon1_accrecsearch_cwra_card_no_column')}
  //                   </TableCell>
  //                   <TableCell
  //                     sx={{
  //                       fontSize: '15px',
  //                       wordBreak: 'keep-all',
  //                       whiteSpace: 'nowrap',
  //                     }}
  //                   >
  //                     {t('icon1_accrecsearch_cn_name_column')}
  //                   </TableCell>
  //                   <TableCell
  //                     sx={{
  //                       fontSize: '15px',
  //                       wordBreak: 'keep-all',
  //                       whiteSpace: 'nowrap',
  //                     }}
  //                   >
  //                     {t('icon1_accrecsearch_eng_name_column')}
  //                   </TableCell>
  //                   <TableCell
  //                     sx={{
  //                       fontSize: '15px',
  //                       wordBreak: 'keep-all',
  //                       whiteSpace: 'nowrap',
  //                     }}
  //                   >
  //                     {t('icon1_accrecsearch_template_on_card_column')}
  //                   </TableCell>
  //                   <TableCell
  //                     sx={{
  //                       fontSize: '15px',
  //                       wordBreak: 'keep-all',
  //                       whiteSpace: 'nowrap',
  //                     }}
  //                   >
  //                     {t('FACE')}
  //                   </TableCell>
  //                   <TableCell
  //                     sx={{
  //                       fontSize: '15px',
  //                       wordBreak: 'keep-all',
  //                       whiteSpace: 'nowrap',
  //                     }}
  //                   >
  //                     {t('date')}
  //                   </TableCell>
  //                   <TableCell
  //                     sx={{
  //                       fontSize: '15px',
  //                       wordBreak: 'keep-all',
  //                       whiteSpace: 'nowrap',
  //                     }}
  //                   >
  //                     {t('Gate_time')}
  //                   </TableCell>
  //                   <TableCell
  //                     sx={{
  //                       fontSize: '15px',
  //                       wordBreak: 'keep-all',
  //                       whiteSpace: 'nowrap',
  //                     }}
  //                   >
  //                     {t('Exit_time')}
  //                   </TableCell>
  //                 </TableRow>
  //               </TableHead>

  //               {!!tableData && tableData.length > 0 && (
  //                 <TableBody>
  //                   {tableData.map((row, ind) => (
  //                     <TableRow
  //                       hover
  //                       key={ind}
  //                       sx={{
  //                         backgroundColor: ind % 2 !== 0 ? '#DCDCDC' : '#ffffff',
  //                       }}
  //                     >
  //                       <TableCell component="th" scope="row">
  //                         {row.vendorDesc}
  //                       </TableCell>
  //                       <TableCell component="th" scope="row">
  //                         {row.subContractorDesc}
  //                       </TableCell>
  //                       <TableCell>{row.smartCardId}</TableCell>
  //                       <TableCell>{row.cwraCardNo}</TableCell>
  //                       <TableCell>{row.chineseName}</TableCell>
  //                       <TableCell>{row.englishName}</TableCell>
  //                       <TableCell>{row.templateOnCard}</TableCell>
  //                       <TableCell>{row.faceId}</TableCell>
  //                       <TableCell>{row.accessDate}</TableCell>
  //                       <TableCell>{row.accessTimeIn}</TableCell>
  //                       <TableCell>{row.accessTimeOut}</TableCell>
  //                     </TableRow>
  //                   ))}
  //                 </TableBody>
  //               )}

  //               {(!tableData || tableData.length === 0) && (
  //                 <TableBody>
  //                   <TableRow>
  //                     <TableCell sx={{ textAlign: 'center' }} colSpan={10}>
  //                       {'No data found !'}
  //                     </TableCell>
  //                   </TableRow>
  //                 </TableBody>
  //               )}
  //             </Table>
  //           </TableContainer>
  //         </div>
  //       </Container>
  //     )}
  //   </>
  // )

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {isSearchPage ? (
        <form>
          <Container maxWidth="md">
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" style={{ textAlign: 'center' }}>
                  <b>{t('icon1_accrecsearch_title_vendor')}</b>
                </Typography>
              </Grid>
              {renderAutocomplete('vendor', sendData.vendor, handleVendorList, vendorList, t('Vendor'), 6)}
              {renderAutocomplete('site', sendData.site, handleSiteId, siteList, t('Site_Name'), 6)}
              {renderDateField('dateFrom', sendData.dateFrom, handleDateFrom, t('date_from'), 6)}
              {renderDateField('dateTo', sendData.dateTo, handleDateTo, t('date_to'), 6, sendData.dateFrom)}
              {renderSelectField('subContractorId', sendData.subContractorId, handleSubcontractors, subList, t('Subcontractors_(direct employers)'), 6)}
              {renderAutocomplete('smartCardObj', sendData.smartCardObj, handleSmartCard, smartCard, t('icon1_useraccesslog_smart_card_label'), 6)}
              {renderTextField('cwraNo', sendData.cwraNo, handleCwraNo, t('icon1_useraccesslog_cwra_card_no_label'), 6)}
              {renderTextField('enName', sendData.enName, handleEnName, t('icon1_useraccesslog_eng_name_label'), 6)}
              {renderTextField('cnName', sendData.cnName, handleCnName, t('icon1_useraccesslog_cn_name_label'), 6)}
              {renderSelectField(
                'toc',
                sendData.toc,
                handleTOC,
                [
                  { id: null, text: 'None' },
                  { id: 'N', text: 'N' },
                  { id: 'Y', text: 'Y' },
                ],
                'Select Option', // 替换为你的标签
                6,
              )}
              {renderSelectField(
                'face',
                sendData.face,
                handleFace,
                [
                  { id: null, text: 'None' },
                  { id: 'N', text: 'N' },
                  { id: 'Y', text: 'Y' },
                ],
                t('icon1_useraccesslog_face_label'),
                6,
              )}
              {renderSelectField(
                'orderBy',
                sendData.orderBy,
                handleOrderBy,
                [
                  { id: null, text: 'None' },
                  { id: 'accessDate', text: 'Access Date' },
                  { id: 'accessTimeIn', text: 'Access Time In' },
                  { id: 'accessTimeOut', text: 'Access Time Out' },
                  { id: 'chineseName', text: 'Chinese Name' },
                  { id: 'englishName', text: 'English Name' },
                  { id: 'smartCardId', text: 'Smart Card Id' },
                ],
                t('icon1_useraccesslog_order_label'),
                6,
                defaultOrderBy,
              )}
              {renderSelectField(
                'downloadType',
                sendData.downloadType,
                handleDownloadType,
                [
                  { id: 'Screen', text: 'Screen' },
                  { id: 'Excel', text: 'Excel' },
                  { id: 'PDF', text: 'PDF' },
                ],
                t('icon1_useraccesslog_export_type_label'),
                6,
              )}
              {renderRadioGroup('orderType', orderType, handleOrderType, 6)}
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <FormControl>
                  <Button variant="contained" onClick={handleSubmit} sx={{ backgroundColor: 'primaryBlue', color: 'commom.white' }}>
                    {t('button_submit')}
                  </Button>
                </FormControl>
                <FormControl sx={{ marginLeft: '20px' }}>
                  <Button variant="contained" onClick={resetForm} sx={{ backgroundColor: 'primaryGreen', color: 'commom.white', marginRight: '10px' }}>
                    {t('button_reset')}
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Container>
        </form>
      ) : (
        <Container maxWidth="false">
          <div style={{ display: 'block', textAlign: 'left', marginTop: '0px' }}>
            <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
              <ArrowBackIcon />
              {t('button_back')}
            </Button>
          </div>
          <div style={{ display: 'block', textAlign: 'right' }}>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportExcel(sendData)}>
              <DownloadIcon />
              {t('button_export_excel')}
            </Button>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportPDF(sendData)}>
              <DownloadIcon />
              {t('button_export_pdf')}
            </Button>
          </div>
          {renderTable(sendData, paginatedData)} {/* 使用分页后的数据 */}
          <TablePagination
            rowsPerPageOptions={[50, 100, 150]} // 可以选择每页显示的行数
            component="div"
            count={totalCount} // 总行数
            count={tableData.length} // 总行数
            rowsPerPage={rowsPerPage} // 每页显示的行数
            page={page} // 当前页
            onPageChange={handleChangePage} // 页码变化处理
            onRowsPerPageChange={handleChangeRowsPerPage} // 每页行数变化处理
          />
        </Container>
      )}
    </>
  )
}

import React, { useState, useRef, useEffect } from 'react'
import {
  Button,
  Box,
  Container,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
  Backdrop,
  MenuItem,
  CircularProgress,
  TextField,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Divider,
  Select,
  InputLabel,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import CustomDialog from 'src/components/common/CustomDialog'
import CustomSnackbar from 'src/components/common/CustomSnackbar'
import DownloadIcon from '@mui/icons-material/Download'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import * as api from 'src/utils/api/workerAttendanceRecord/AutoPayApi'
import SaveButton from 'src/components/icon/icon1/autoPay/updateWorkerMPF/SaveButton'
import Checking from 'src/components/icon/icon1/autoPay/updateWorkerMPF/Checking'
import { confirm } from 'src/components/common'

export default function SearchSalaryAndMpf(props) {
  const { type, setType, data } = props
  const { t } = useTranslation()
  const [sendData, setSendData] = React.useState({
    checkAll: true,
    checkEn: true,
    check1: true,
    check2: true,
    check3: true,
    check4: true,
    check5: true,
    check6: true,
    check7: true,
    check8: true,
  })
  const [open, setOpen] = React.useState(false)
  const [isSearchPage, setIsSearchPage] = useState(true)
  const [step, setStep] = useState(false)
  const [subcontractorList, setSubcontractorList] = React.useState([])
  const [vendorList, setVendorList] = React.useState([])
  const [siteList, setSiteList] = React.useState([])
  const [shortBatchList, setShortBatchList] = React.useState({})
  const [periodList, setPeriodList] = React.useState([])
  const [tableData, setTableData] = React.useState([])
  const [dialog, setDialog] = useState({
    content: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setDialog({ ...dialog, open: false })
        }}
      >
        確認
      </Button>
    ),
    open: false,
  })

  const [snackbar, setSnackbar] = useState({
    message: '',
    action: (
      <Button
        variant="contained"
        onClick={() => {
          setSnackbar({ ...snackbar, open: false })
        }}
      >
        確認
      </Button>
    ),
    open: false,
  })

  useEffect(() => {
    const getData = async () => {
      setOpen(true)
      // 獲取部分糧期
      let getAllData = {}
      // 獲取承判商用戶
      let vendorList = await api.getVendorList()
      if (vendorList) {
        setVendorList([...vendorList])
      }
      // let getAllData = await api.getWorkerAutopayMainSearch()
      setOpen(false)
    }
    getData()
    setSendData({
      ...sendData,
    })
  }, [step])
  const handleBack = () => {
    setIsSearchPage(true)
  }

  const handleCancel = () => {
    setSendData({})
  }
  const ReportTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: palette.report.header,
      // color: palette.common.white,
      fontSize: '15px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }))

  const handleTxt = (e) => {
    setSendData({ ...sendData, txtSearch: e.target.value })
  }
  const ReportTableRow = styled(TableRow)(({ theme }) => ({
    // "&:nth-of-type(odd)": {
    //   backgroundColor: palette.report.record,
    // },
  }))

  const handleSubcontractorList = (item) => {
    setSendData({
      ...sendData,
      subContractorId: item.subContractorId,
      subContractorDesc: item.subContractorDesc,
    })
  }
  const handleSave = async () => {
    const save = async () => {
      if (!sendData.vendorId) {
        alert(t('search_choose_vendor_tips'))
        return
      }
      if (!sendData.dateFrom || sendData.dateFrom === '') {
        alert(t('Please_select_Pay_and_Contribution_Period'))
        return
      }
      if (!sendData.subBatchPeriod || sendData.subBatchPeriod === '') {
        alert(t('Please_select_the_grain_period'))
        return
      }
      setOpen(true)
      let saveApi = await api.salaryAndMPFSearchReport(sendData)

      if (saveApi) {
        setTableData(saveApi)
        setOpen(false)
        setIsSearchPage(false)
      } else {
        setOpen(false)
        alert('未知錯誤')
      }
    }
    save()
    console.log('sendData', sendData)
  }
  const handleShortBatchList = (item) => {
    const getSub = async () => {
      setOpen(true)
      //
      siteList.splice(0, siteList.length)
      let getSiteList = await api.getSiteList({
        ...sendData,
        subBatchId: item.subBatchId,
        pdDateFrom: item.pdDateFrom,
        pdDateTo: item.pdDateTo,
        value: item.value,
      })
      if (getSiteList) {
        setSiteList(getSiteList)
      }
      console.log('getSiteList', getSiteList)
      setSendData({
        ...sendData,
        siteDesc: '',
        subBatchPeriod: item.value,
        pdDateFrom: item.pdDateFrom,
        pdDateTo: item.pdDateTo,
        subBatchId: item.subBatchId,
      })
      setOpen(false)
    }
    getSub()
  }

  const handleSiteList = (item) => {
    setOpen(true)
    const getSub = async () => {
      //
      setSendData({
        ...sendData,
        siteId: item.siteId,
        siteDesc: item.siteDesc,
        shortDateFrom: '',
      })
      setOpen(false)
    }
    getSub()
  }

  const handlePeriodList = (item) => {
    setOpen(true)
    const getSub = async () => {
      //
      siteList.splice(0, siteList.length)
      subcontractorList.splice(0, subcontractorList.length)
      let getShortBatchList = await api.getShortBatchList({
        ...sendData,
        dateFrom: item.id,
      })
      if (getShortBatchList) {
        setShortBatchList(getShortBatchList)
      }
      console.log('getShortBatchList', getShortBatchList)

      let getSubcontractorList = await api.getSubcontractorList({
        ...sendData,
        dateFrom: item.id,
      })
      if (getSubcontractorList) {
        setSubcontractorList(getSubcontractorList)
      }

      console.log('item', item)
      setSendData({
        ...sendData,
        dateFrom: item.id,
        datePeriod: item.text,
        shortDateFrom: '',
        payrollType: getShortBatchList.payrollType,
        status: item.status,
        value: item.subBatchPeriod,
      })
      setOpen(false)
    }
    getSub()
  }

  const handleVendorList = (item) => {
    setOpen(true)
    const getSub = async () => {
      //
      periodList.splice(0, periodList.length)
      siteList.splice(0, siteList.length)
      subcontractorList.splice(0, subcontractorList.length)
      setShortBatchList({})
      let sendPeriod = await api.getPeriod({ ...sendData, vendorId: item.id })
      if (sendPeriod) {
        setPeriodList([...sendPeriod])
      }
      setSendData({
        ...sendData,
        vendorId: item.id,
        vendorDesc: item.text,
      })
      setOpen(false)
    }
    getSub()
  }

  return (
    <>
      <CustomDialog open={dialog.open} title={dialog.title} content={dialog.content} action={dialog.action} />
      <CustomSnackbar open={snackbar.open} message={snackbar.message} action={snackbar.action} />
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {isSearchPage && (
        <Container maxWidth="sm">
          <div style={{ textAlign: 'center' }}>
            <Typography variant="h4" sx={{ mb: '20px' }}>
              <b>{t('check_workers_salary_and_MPF_information')}</b>
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} md={12}>
                <TextField error={!sendData.vendorId} sx={{ maxWidth: '400px', textAlign: 'left' }} fullWidth size="small" value={sendData.vendorId || ''} label={t('icon_vas_wsat_vendor')} select>
                  {(!vendorList || vendorList.length === 0) && (
                    <MenuItem value="" onClick={() => handleVendorList({ vendorId: null, text: '' })}>
                      None
                    </MenuItem>
                  )}
                  {vendorList.map((item, index) => (
                    <MenuItem onClick={() => handleVendorList(item)} key={index} value={item.id}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6} md={12}>
                <TextField
                  error={!sendData.dateFrom}
                  sx={{ maxWidth: '400px', textAlign: 'left' }}
                  fullWidth
                  size="small"
                  value={sendData.dateFrom || ''}
                  label={t('Payroll_and_Contribution_Period')}
                  select
                >
                  {(!periodList || periodList.length === 0) && <MenuItem value="">None</MenuItem>}

                  {periodList.map((item, index) => (
                    <MenuItem onClick={() => handlePeriodList(item)} key={index} value={item.id}>
                      {item.text} ({item.status === 'C' ? t('finish') : t('to_be_continued')})
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* 糧期 */}
              <Grid item xs={6} md={12}>
                <TextField
                  error={!sendData.subBatchPeriod}
                  sx={{ maxWidth: '400px', textAlign: 'left' }}
                  fullWidth
                  size="small"
                  label={t('icon_vas_wsat_group_type_salary_period')}
                  value={sendData.subBatchPeriod || ''}
                  select
                >
                  {JSON.stringify(shortBatchList) === '{}' && <MenuItem value="">None</MenuItem>}

                  {shortBatchList.dateList?.map((item, index) => (
                    <MenuItem key={index} value={item.value} onClick={() => handleShortBatchList(item)}>
                      {item.text === '完成整個糧期' ? t('icon1_autopay_oneBatch') : item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={6} md={12}>
                {/* 地盤名稱 */}
                <TextField sx={{ maxWidth: '400px', textAlign: 'left' }} label={t('Site_Name')} size="small" fullWidth value={sendData.siteId || ''} select>
                  {(!siteList || siteList.length === 0) && <MenuItem value="">None</MenuItem>}
                  {siteList &&
                    siteList.map((item, index) => (
                      <MenuItem key={index} value={item.siteId} onClick={() => handleSiteList(item)}>
                        {item.siteDesc}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={6} md={12}>
                <TextField sx={{ maxWidth: '400px', textAlign: 'left' }} label={t('Subcontractors')} value={sendData.subContractorId || ''} size="small" fullWidth select>
                  {(!subcontractorList || subcontractorList.length === 0) && <MenuItem value="">None</MenuItem>}
                  {subcontractorList &&
                    subcontractorList.map((item, index) => (
                      <MenuItem onClick={() => handleSubcontractorList(item)} key={index} value={item.subContractorId}>
                        {item.subContractorDesc}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={6} md={12}>
                <TextField
                  sx={{ maxWidth: '400px', textAlign: 'left' }}
                  label={t('Chinese_English_Name_or_Cardholder_ID')}
                  value={sendData.txtSearch || ''}
                  onChange={handleTxt}
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Button onClick={handleSave} sx={{ mt: '40px', width: '150px' }} variant="contained">
              {t('search_btn_title')}
            </Button>

            <Button onClick={handleCancel} sx={{ mt: '40px', ml: '10px', width: '150px' }} variant="contained">
              {t('button_reset')}
            </Button>
          </div>
        </Container>
      )}

      {!isSearchPage && (
        <Container maxWidth="false">
          <div style={{ display: 'block', textAlign: 'left', marginTop: '0px' }}>
            <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
              <ArrowBackIcon />
              {t('button_back')}
            </Button>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Typography variant="h4">
              <b>{t('worker_salary/MPF_information')}</b>
            </Typography>
          </div>
          {/* 表頭數據 */}
          <TableContainer>
            <Table padding="none" sx={{ textAlign: 'left', mt: '10px' }} size="small">
              <TableBody>
                <TableRow>
                  {/* 承判商:	 */}
                  <TableCell sx={{ fontSize: '15px', width: 200, borderBottom: 'none' }}>
                    <b>{t('icon_vas_wsat_vendor')}:</b>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '15px',
                      textAlign: 'left',
                      borderBottom: 'none',
                    }}
                  >
                    {sendData.vendorDesc}
                  </TableCell>
                </TableRow>
                <TableRow>
                  {/* 大糧期:	 */}
                  <TableCell sx={{ fontSize: '15px', width: 200, borderBottom: 'none' }}>
                    <b>{t('Big_Pay_Period')}:</b>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '15px',
                      textAlign: 'left',
                      borderBottom: 'none',
                    }}
                  >
                    {sendData.datePeriod}
                  </TableCell>
                </TableRow>
                <TableRow>
                  {sendData.payrollType === 'P' ? (
                    <>
                      {/* 部分糧期: */}
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          width: 200,
                          borderBottom: 'none',
                        }}
                      >
                        <b>{t('糧期')}:</b>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          textAlign: 'left',
                          borderBottom: 'none',
                        }}
                      >
                        {sendData.subBatchPeriod}
                      </TableCell>
                    </>
                  ) : (
                    <>
                      {/* 短糧期: */}
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          width: 200,
                          borderBottom: 'none',
                        }}
                      >
                        <b>{t('Short_Pay_Period')}:</b>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '15px',
                          textAlign: 'left',
                          borderBottom: 'none',
                        }}
                      >
                        {sendData.subBatchPeriod !== 'F' ? sendData.subBatchPeriod : '完成整个糧期'}
                      </TableCell>
                    </>
                  )}
                </TableRow>
                <TableRow>
                  {/* 地盤名稱: */}
                  <TableCell sx={{ fontSize: '15px', width: 200, borderBottom: 'none' }}>
                    <b>{t('Site_Name')}:</b>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '15px',
                      textAlign: 'left',
                      borderBottom: 'none',
                    }}
                  >
                    {sendData.siteDesc !== null && sendData.siteDesc !== '' ? sendData.siteDesc : t('All_site1')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  {/* 狀態:	 */}
                  <TableCell sx={{ fontSize: '15px', width: 200, borderBottom: 'none' }}>
                    <b>{t('Status')}:</b>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '15px',
                      textAlign: 'left',
                      borderBottom: 'none',
                    }}
                  >
                    {sendData.status === 'C' ? t('finish') : t('to_be_continued')}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {tableData.subDataMain?.length > 0 ? (
            <>
              {tableData.subDataMain.map((item, index) => (
                <div key={index}>
                  {sendData.siteId !== '' ? (
                    <Typography sx={{ display: 'block', fontSize: '15px', pt: '20px' }}>
                      <b>
                        {t('Subcontractor_Name')}: {item.subdataKey}
                      </b>
                    </Typography>
                  ) : (
                    ''
                  )}

                  <TableContainer>
                    <Table
                      sx={{
                        mt: '10px',
                        minWidth: 650,
                        '& .MuiTableCell-root': {
                          paddingLeft: '6px',
                          paddingRight: '6px',
                        },
                      }}
                      size="small"
                    >
                      <TableHead>
                        <TableRow hover sx={{ border: '1px' }}>
                          <TableCell sx={{ fontSize: '15px' }}>No</TableCell>
                          {/* 英文姓名 */}
                          <TableCell align="center" sx={{ fontSize: '15px', wordBreak: 'keep-all' }}>
                            {t('EnglishName')}
                          </TableCell>
                          {/* 中文姓名 */}
                          <TableCell align="center" sx={{ fontSize: '15px', wordBreak: 'keep-all' }}>
                            {t('ChineseName')}
                          </TableCell>
                          {/* 身份証號碼 */}
                          <TableCell align="center" sx={{ fontSize: '15px', wordBreak: 'keep-all' }}>
                            {t('ID_number')}
                          </TableCell>
                          {/* 實際上班日期(由) */}
                          {/* <TableCell
                            align="center"
                            sx={{
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              minWidth: '130px',
                            }}
                          >
                            {t('Actual_start_date_(From)')}
                          </TableCell> */}
                          {/* 實際上班日期(至) */}
                          {/* <TableCell
                            align="center"
                            sx={{
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              minWidth: '130px',
                            }}
                          >
                            {t('Actual_start_date_(To)')}
                          </TableCell> */}
                          {/* 工人上班日數 */}
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              textAlign: 'right',
                            }}
                          >
                            {t('Number_Of_Workers_Working_Days')}
                          </TableCell>
                          {/* 實際上班日數 */}
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              textAlign: 'right',
                            }}
                          >
                            {t('Actual_number_of_working_days')}
                          </TableCell>
                          {/* 每天入息 */}
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              textAlign: 'right',
                            }}
                          >
                            {t('Worker_income')}
                          </TableCell>
                          {/* 工人入息 */}
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              textAlign: 'right',
                            }}
                          >
                            {t('Average_income')}
                          </TableCell>
                          {/* 登記平均入息 */}
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              textAlign: 'right',
                            }}
                          >
                            {t('icon1_autopay_button7_label6')}
                          </TableCell>
                          {/* 員工戶口號碼 */}
                          <TableCell align="center" sx={{ fontSize: '15px', wordBreak: 'keep-all' }}>
                            {t('Employee_Account_Number')}
                          </TableCell>
                          {/* 計劃成員編號 */}
                          <TableCell align="center" sx={{ fontSize: '15px', wordBreak: 'keep-all' }}>
                            {t('plan_Member_Number')}
                          </TableCell>
                          {/* {sendData.subBatchPeriod === 'F' ? (
                            <>
                             
                              <TableCell align="center" sx={{ fontSize: '15px', wordBreak: 'keep-all' }}>
                                {t('Actual_payroll_paid')}
                              </TableCell>
                             
                              <TableCell align="center" sx={{ fontSize: '15px', wordBreak: 'keep-all' }}>
                                {t('Number_of_working_days_paid')}
                              </TableCell>
                            </>
                          ) : null} */}
                          {/* 日薪/月薪 */}
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: '15px',
                              wordBreak: 'keep-all',
                              minWidth: '80px',
                            }}
                          >
                            {t('Daily/Monthly_Salary')}
                          </TableCell>
                          {/* 備註 */}
                          <TableCell align="center" sx={{ fontSize: '15px', wordBreak: 'keep-all' }}>
                            {t('Remark')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableData.subDataMain[index].subdataValue.length > 0 ? (
                          <>
                            {tableData.subDataMain[index].subdataValue.map((row, i) => (
                              <TableRow
                                sx={{
                                  backgroundColor: i % 2 !== 0 ? '#DCDCDC' : '#ffffff',
                                }}
                                hover
                                key={i}
                              >
                                <TableCell sx={{ fontSize: '15px' }} component="th" scope="row">
                                  {i + 1}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: '15px',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {row.englishName}{' '}
                                </TableCell>
                                <TableCell align="center" sx={{ fontSize: '15px' }}>
                                  {row.chineseName}
                                </TableCell>
                                <TableCell align="center" sx={{ fontSize: '15px' }}>
                                  {row.hkid}
                                </TableCell>
                                {/* <TableCell align="right" sx={{ fontSize: '15px' }}>
                                  {row.shortdatefrom}
                                </TableCell> */}
                                {/* <TableCell align="right" sx={{ fontSize: '15px' }}>
                                  {row.shortdateto}
                                </TableCell> */}
                                <TableCell
                                  align="right"
                                  sx={{
                                    fontSize: '15px',
                                    textAlign: 'right',
                                  }}
                                >
                                  {row.workDay}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    fontSize: '15px',
                                    textAlign: 'right',
                                  }}
                                >
                                  {row.workDayAct}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    fontSize: '15px',
                                    textAlign: 'right',
                                  }}
                                >
                                  {row.relevantIncome.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    fontSize: '15px',
                                    textAlign: 'right',
                                  }}
                                >
                                  {row.workDayAct && row.relevantIncome !== 0 && row.workDayAct !== null && row.relevantIncome !== null
                                    ? (row.relevantIncome / row.workDayAct).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                                    : 0}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    fontSize: '15px',
                                    textAlign: 'right',
                                  }}
                                >
                                  {row.basicSalary !== null ? row.basicSalary.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0.0}
                                </TableCell>
                                <TableCell align="right" sx={{ fontSize: '15px' }}>
                                  {row.bankAccount}
                                </TableCell>
                                <TableCell align="right" sx={{ fontSize: '15px' }}>
                                  {row.mpfScheme}
                                </TableCell>
                                {/* {sendData.payrollType === 'S' ? (
                                  sendData.subBatchPeriod === 'F' ? (
                                    <>
                                      <TableCell
                                        align="center"
                                        sx={{
                                          fontSize: '15px',
                                          textAlign: 'right',
                                        }}
                                      >
                                        {row.dailyIncome}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        sx={{
                                          fontSize: '15px',
                                          textAlign: 'right',
                                        }}
                                      >
                                        {row.workDayAct}
                                      </TableCell>
                                    </>
                                  ) : null
                                ) : sendData.payrollType === 'F' ? (
                                  <>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        fontSize: '15px',
                                        textAlign: 'right',
                                      }}
                                    >
                                      {0}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        fontSize: '15px',
                                        textAlign: 'right',
                                      }}
                                    >
                                      {0}
                                    </TableCell>
                                  </>
                                ) : null} */}

                                <TableCell align="center" sx={{ fontSize: '15px' }}>
                                  {/* {row.type === 'D' ? '日薪' : '月薪'} */}
                                  {'日薪'}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: '15px',
                                    wordBreak: 'keep-all',
                                  }}
                                >
                                  {row.payrollRemark}
                                </TableCell>
                                {/* <TableCell align="left" sx={{ fontSize: '15px' }}>{row.mpfScheme}</TableCell>
                                                        <TableCell align="left" sx={{ fontSize: '15px' }}>{row.payrollRemark}</TableCell> */}
                              </TableRow>
                            ))}
                          </>
                        ) : (
                          <TableRow>
                            <TableCell colSpan="17" sx={{ textAlign: 'center', fontSize: '15px' }}>
                              No Record
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ))}
            </>
          ) : (
            <Typography sx={{ textAlign: 'center', fontSize: '20px', mt: '20px' }}>No Record!</Typography>
          )}
        </Container>
      )}
    </>
  )
}
